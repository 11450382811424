$font-family-base: "product_sansregular", sans-serif;
$primary: #ef5b59;
$white: #ffffff !default;
$gray-400: #f8f8f8 !default;
$gray-600: #97999b !default;
$gray-900: #454545 !default;
$body-color: $gray-900 !default;
$body-bg: $gray-400 !default;
$border-color: #cbcccd !default;

$link-decoration: none !default;

$input-bg: $white;
$input-border-radius: 1rem;
$input-border-color: $border-color;
$input-focus-box-shadow: none !default;
$input-padding-y: 0.95rem !default;
$input-padding-x: 1.25rem !default;
$form-check-input-focus-box-shadow: $input-focus-box-shadow;
$form-check-input-border: 1px solid $border-color;
$form-check-input-width: 1.25em !default;
$form-select-focus-box-shadow: $input-focus-box-shadow;
$form-select-indicator-padding: 2.75rem !default;

$modal-content-border-width: none !default;
$modal-content-border-radius: 1rem;
$modal-md: 450px;
$modal-dialog-margin: 1.5rem;
$modal-backdrop-bg: #454545;
$modal-backdrop-opacity: 0.6;

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 5,
) !default;

$progress-height: 3px;
$progress-border-radius: 2px;
$progress-bar-bg: #007281;
$progress-bg: $gray-400;
