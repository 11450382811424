.fw-bold {
  font-family: "product_sansbold";
}

.text-gray {
  color: $gray-600;
}

.form-check-input {
  margin-top: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.w-100px {
  min-width: 100px;
}

.f-12 {
  font-size: 12px;
}

.f-10 {
  font-size: 10px;
}

.btn {
  height: 56px;
  align-items: center;
  border-radius: 30px;
  display: inline-flex;
  padding-inline: 50px;
  justify-content: center;

  &.btn-icon {
    gap: 10px;
  }

  &.btn-outline-secondary {
    color: $body-color;
    border: 1px solid $gray-600;

    &:hover {
      background: $gray-400 !important;
    }
  }

  &.btn-outline-primary {
    &:hover {
      color: $white;

      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  &.btn-sm {
    height: 40px;
    padding-inline: 30px;
  }

  &.btn-white {
    border: 0;
    height: 36px;
    color: $primary;
    background: $white;
    font-size: 0.875rem;
    padding-inline: 12px;
    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.15);

    &:hover {
      color: $primary !important;
      background: $white !important;
    }
  }

  &.btn-gray {
    color: $gray-600 !important;

    img {
      filter: grayscale(100%);
    }

    &:hover {
      color: $gray-600 !important;
    }
  }

  &.btn-green {
    color: #007281;
    border-radius: 1rem;
    background: none !important;
    border: 1px solid #007281 !important;
  }
}

.back-cta {
  width: 40px;
  height: 40px;
  display: flex;
  left: 0.75rem;
  cursor: pointer;
  position: absolute;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  border: 1px solid $border-color;
}

.auth-wrapper {
  min-height: 100vh;
  padding-block: 3rem 4rem;

  .auth-box {
    z-index: 1;
    padding: 2rem;
    margin-top: 2rem;
    position: relative;
    background: $white;
    border-radius: 1rem;
    box-shadow: 0 44px 65px #b0b7c330;

    .social-login {
      li {
        width: 56px;
        height: 56px;
        display: flex;
        cursor: pointer;
        border-radius: 100%;
        align-items: center;
        justify-content: center;
        border: 1px solid $gray-600;

        &:hover {
          background: $gray-400;
        }
      }
    }
  }
}

.input-icon {
  position: relative;

  &.right-only {
    .icon-duo {
      padding-inline: 1.25rem 3.75rem;
    }
  }

  .icon-single {
    padding-inline: 3.75rem 0;
  }

  .icon-duo {
    padding-inline: 3.75rem;
  }

  img {
    top: 1rem;
    position: absolute;

    &.icon-left {
      left: 1.25rem;
    }

    &.icon-right {
      right: 1.25rem;
    }
  }

  &.bar-left {
    &::before {
      content: "";
      height: 24px;
      width: 1px;
      background: #8d9092;
      position: absolute;
      top: 51.5%;
      left: 52.5px;
      transform: translateY(-50%);
    }
  }

  input[type="date"]::-webkit-calendar-picker-indicator,
  input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    background-size: 20px !important;
    background: url(http://localhost:3000/img/icon_calendar.svg) no-repeat;
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    background-size: 20px !important;
    background: url(http://localhost:3000/img/icon_clock.svg) no-repeat;
  }
}

.reset-input {
  .form-control {
    padding: 9.2px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    background: $gray-400;
  }
}

.code-input {
  input {
    border-color: #f8f8f8;

    &:focus,
    &:focus-visible,
    &:active {
      border-color: black;
    }
  }
}

.result-listing {
  hr {
    margin-left: 72px;
  }
}
.avatar {
  border-radius: 100%;
  overflow: hidden;

  &.small {
    width: 20px;
    height: 20px;
  }

  &.normal {
    width: 40px;
    height: 40px;
  }

  &.big {
    width: 115px;
    height: 115px;
    max-width: 115px;
    max-height: 115px;
    margin: auto;
    overflow: hidden;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}


.img-outer {
  width: 32px;
  height: 32px;
  min-width: 32px;
  max-width: 32px;
  overflow: hidden;
  border-radius: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.img-md {
    width: 40px;
    height: 40px;
    min-width: 40px;
    max-width: 40px;
  }

  &.img-lg {
    width: 56px;
    height: 56px;
    min-width: 56px;
    max-width: 56px;
  }

  &.img-xlg {
    width: 115px;
    height: 115px;
    min-width: 115px;
    max-width: 115px;
  }
}

.event-user {
  top: 50%;
  left: 50%;
  width: 225px;
  margin-top: 1rem;
  margin-left: -128.5px;
  justify-content: center;
  transform: translateY(-50%);
}

.theme-nav {
  display: flex;
  gap: 1rem;

  .nav-link {
    padding: 0 !important;
    line-height: 1.8;
    color: $gray-600 !important;
    text-align: center;

    .outline-icon {
      width: 32px;
    }

    .solid-icon {
      left: 50%;
      opacity: 0;
      position: absolute;
      margin-left: -16px;
    }

    &.active {
      &.navitem {
        color: $body-color !important;

        .outline-icon {
          opacity: 0;
        }

        .solid-icon {
          opacity: 1;
        }
      }
    }

    &:hover {
      color: $body-color;
    }
  }
}

.unread {
  .count {
    width: 15px;
    height: 15px;
    color: $white;
    background: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    border-radius: 100%;
    line-height: 2;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-left: 12px;
  }

  &.mobile {
    .count {
      top: -3px;
      left: auto;
      right: -5px;
      margin-left: 0;
      transform: none;
    }
  }
}

.doodle {
  text-align: center;
  width: 250px;
  font-size: 1.25em;
  position: fixed;
  right: 0;
  bottom: 180px;

  img {
    position: absolute;
    top: 45px;
    left: 75%;
  }

  @media (max-width: 767px) {
    text-align: start;
    width: 270px;
    font-size: 1.35rem;
    right: 50%;
    transform: translateX(50%);
    img {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.offcanvas-header {
  justify-content: center;

  .offcanvas-title {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .btn-close {
    left: 1rem;
    opacity: 1;
    padding: 0;
    width: 40px;
    height: 40px;
    position: absolute;
    background: url(http://localhost:3000/img/icon_chevron_left_circle.svg) no-repeat center;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.offcanvas-body {
  padding: 0 1rem;
}

.notifcation {
  .day {
    display: flex;
    font-size: 18px;
    margin: 1rem 0;
    justify-content: space-between;
  }

  .notify {
    margin-top: 1rem;
    border-radius: 12px;
    padding: 0.669rem 1rem;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid #ff8888;

      .notify-action {
        opacity: 1;
      }
    }

    .notify-content {
      margin-top: 0.45rem;

      p {
        font-size: 12px;
      }
    }

    .notify-action {
      opacity: 0;

      img {
        cursor: pointer;
      }
    }
  }
}

.user-profile {
  .profile-img {
    text-align: center;
    margin: 1.5rem auto;

    &.upload-img {
      max-width: 115px;
      position: relative;
    }

    .img-outer {
      margin: auto;
    }
  }

  .profile-listing {
    li {
      a {
        color: $body-color;
      }

      + li {
        margin-top: 1rem;
      }
    }
  }
}

.expense-listing {
  > div {
    margin-bottom: -1px;

    .expense-user {
      min-width: 80px;
      max-width: 80px;
      position: relative;
    }

    .expense-title {
      padding: 1.75rem 0;
      border-bottom: 1px solid #cbcccd80;
    }
  }
}

.user-initials {
  width: 40px;
  height: 40px;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  align-items: center;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  background: #c3e2ff;

  &.small {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }

  &.big {
    width: 115px;
    height: 115px;
    font-size: 40px;
  }

  &.green {
    color: $white;
    background: #007281;
  }

  &.orange {
    background: #ff9141;
  }

  .expense-detail-listing {
    margin-bottom: 1rem;
  }

  border-bottom: 1px solid #cbcccd80;
}

.balance {
  gap: 12px;
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;

  p {
    font-size: 20px;
    margin-bottom: 0;

    .price {
      color: $white;
      padding: 1px 1rem;
      line-height: 22px;
      margin-left: 0.5rem;
      border-radius: 49% 50% 50% 50% / 10% 10% 12% 10%;
      font-size: 18px;

      &.success {
        background: rgba(110, 185, 91, 0.81);
      }

      &.danger {
        background: rgba(255, 0, 0, 0.76);
      }
    }
  }
}

.balance-listing {
  margin-block: 5px;
  padding-bottom: 0.35rem;
  border-bottom: 1px solid #cbcccd80;

  h5 {
    margin-bottom: 0;
  }
}

.box {
  padding: 0;
  overflow: hidden;
  background: $white;
  border-radius: 1rem;

  hr {
    opacity: 1;
    margin: 1rem -1rem;
    border-top: 1px solid #cbcccd80;
  }
}

.mxw-170 {
  max-width: 170px;
}

.theme-tab {
  &.nav-tabs {
    border: none;

    .nav-item {
      .nav-link {
        border: none;
        background: none;
        color: $gray-600;
        padding: 0.15rem 1rem;
        border-bottom: 2px solid transparent;

        &:hover {
          color: $primary;
        }

        &.active {
          color: $primary;
          border-bottom: 2px solid $primary;
        }
      }
    }
  }

  &.tab-reverse {
    justify-content: space-between;

    + .tab-content {
      overflow-y: auto;
      margin-inline: -1rem;
      padding-inline: 1rem;
      height: calc(100vh - 300px);
    }
  }
}

.theme-modal {
  bottom: 0;
  height: auto;
  top: initial;

  .modal-dialog {
    margin-right: 1.75rem;
    margin-bottom: 1.75rem;

    .modal-content {
      border-radius: 0;
      border: 3px solid $primary;

      .modal-header {
        .btn {
          padding: 0;
          border: none !important;
        }
      }

      .modal-body {
        height: calc(100vh - 200px);
      }
    }
  }

  &.with-footer {
    .modal-dialog {
      .modal-content {
        .modal-body {
          height: calc(100vh - 272px);
        }
      }
    }
  }
}

.modal-alert {
  .modal-content {
    border-radius: 10px;
  }
}

.theme-checkbox {
  .form-check {
    margin-bottom: 0.5rem;
    padding: 0 0 0.5rem;
    border-bottom: 1px solid #cbcccd80;

    .form-check-input {
      border: none;
      background-size: 20px !important;
      background: url(http://localhost:3000/img/icon_unchecked.svg) no-repeat;

      &:checked[type="checkbox"] {
        background: url(http://localhost:3000/img/icon_checked.svg);
      }
    }
  }
}

.currency-input {
  position: relative;

  span {
    top: 50%;
    right: 1.25rem;
    color: $gray-600;
    line-height: 1.2;
    position: absolute;
    padding-left: 1rem;
    transform: translateY(-50%);
    border-left: 1px solid $gray-600;
  }
}

.msg-listing {
  overflow-y: auto;
  margin-top: 1.5rem;
  padding-right: 1rem;
  max-height: calc(100vh - 300px);

  .msg-item {
    + .msg-item {
      margin-top: 1.5rem;
    }

    .time,
    .msg-content {
      font-size: 12px;
    }

    .msg-count {
      height: 18px;
      color: $white;
      display: flex;
      min-width: 18px;
      align-items: center;
      border-radius: 100%;
      background: $primary;
      justify-content: center;
    }

    .inner-img {
      padding: 0;
      right: -5px;
      width: 26px;
      bottom: -5px;
      height: 26px;
      position: absolute;
      border-radius: 100%;

      img, span {
        border: 3px solid #fff;
        border-radius: 100%;
      }
    }
  }
}

.chatroom {
  overflow-y: auto;
  margin-bottom: 1rem;
  max-height: calc(100vh - 300px);

  .chatdays {
    color: $white;
    margin: 1.5rem auto;
    width: min-content;
    padding: 0.25rem 1rem;
    border-radius: 100px;
    backdrop-filter: blur(10px);
    background: rgba(18, 18, 18, 0.3);
  }

  .load-messages {
    color: $white;
    margin: 1.5rem auto;
    background: rgba(18, 18, 18, 0.3);
    display: inline-block;
    padding: 0.25rem 1rem;
    border-radius: 100px;
  }

  .chat-outer {
    .chatbox-inner {
      font-size: 14px;
      padding: 0.625rem 1rem;
      background: #f8f8f8;
      max-width: calc(100% - 80px);
      border-radius: 0px 24px 24px 24px;
    }

    &:hover {
      .chatbox-add-reaction {
        opacity: 1;
      }
    }

    .chatbox-add-reaction {
      margin-right: -15px;
      z-index: 2;
      opacity: 0;
      cursor: pointer;
    }

    .chatbox-reaction {
      font-size: 12px;
      padding: 2px 6px;
      border-radius: 25px;
      background: $gray-400;
    }

    + .chat-outer {
      margin-top: 1.25rem;
    }

    &.right {
      flex-direction: row-reverse;

      .chatbox-inner {
        color: $white;
        background: $primary;
        border-radius: 24px 0px 24px 24px;
      }
    }
  }
}

.modal-backdrop {
  z-index: 9999;
}

.modal {
  z-index: 99999;
}

.chat-send {
  &.event-widget {
    .add-cta {
      padding-inline: 50px;
      margin-top: 70px !important;
      background: $primary !important;
    }
  }

  .dropdown {
    position: absolute;

    .btn {
      padding: 0;
      border: none !important;
      background: none !important;

      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      padding: 0;
      border: none;
      min-width: 14rem;

      .dropdown-item {
        gap: 0.5rem;
        display: flex;
        align-items: center;
        padding: 1rem 1.25rem;
        border: 1px solid #cbcccd80;

        &:first-child {
          border-radius: 10px 10px 0 0;
        }

        &:nth-child(5) {
          border-radius: 0 0 10px 10px;
        }

        &:hover,
        &:focus,
        &:active {
          color: $gray-900 !important;
          background: $gray-400 !important;
        }
      }

      .btn {
        width: 100%;
        margin-top: 10px;
        border-radius: 10px;
        border: 1px solid #cbcccd80 !important;
      }
    }
  }

  .form-control {
    border: none;
    border-radius: 100px;
    background: #f0f2f5;
  }
}

.three-dot {
  font-size: 25px;
  color: grey;
  margin-bottom: -29px;
}

.invite-user {
  .user-status {
    display: flex;
    min-width: 100px;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;


    img {
      width: 12px;
    }
  }

  + .invite-user {
    margin-top: 1.25rem;
  }
}

.event-outer {
  gap: 1rem;
  display: flex;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 8px;
  background: #f2f3f4;

  .event-img {
    width: 150px;
    height: 100px;
    overflow: hidden;
    border-radius: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .event-content {
    .event-info {
      li {
        gap: 0.5rem;
        display: flex;
        font-size: 13px;
        align-items: center;
        margin-bottom: 0.5rem;

        &.text-blue {
          color: #007281;
        }
      }
    }
  }

  @media (max-width: 765px) {
    padding: 0.5rem;
    .event-img {
      width: 150px;
      height: 100px;
    }
    .event-content {
      .event-info {
        li {
          gap: 2px;
          margin-bottom: 0;
        }
      }
    }
  }
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background: #007281;
}

.event-detail {
  .event-cover-img {
    height: 290px;
    position: relative;
    margin: -1rem -1rem 0;
    background: $gray-400;

    .cover-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .controls {
      top: 0;
      width: 100%;
      gap: 0.5rem;
      display: flex;
      margin-top: 1.5rem;
      position: absolute;
      padding-inline: 1.5rem;
    }

    .dropdown-menu {
      background: rgba($color: $white, $alpha: 0.8);
      border: 0;

      .dropdown-item {
        gap: 12px;
        display: flex;
      }
    }
  }

  .event-card {
    top: 34px;
    padding: 1rem;
    position: sticky;
    margin-top: -90px;
    margin-inline: 1.5rem;
    border-radius: 1.5rem;
    backdrop-filter: blur(14.5px);
    background: rgba($color: $white, $alpha: 0.7);
    box-shadow: 0 4px 80px rgba(0, 0, 0, 0.15);

    .event-info {
      li {
        margin-top: 0.35rem;

        img {
          margin-top: -4px;
        }
      }
    }
  }
}

.current-event-widget {
  margin: 0.5rem 0;
  border-radius: 12px;
  backdrop-filter: blur(5.5px);
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 3px 20px 0 #0000001a;

  .event-img {
    width: 72px;
    height: 72px;
    overflow: hidden;
    border-radius: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .icon-rounded {
    width: 30px;
    height: 30px;
    padding: 5px;
    cursor: pointer;
    border-radius: 100%;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    &.active {
      background: $primary;

      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}

.widget-outer {
  max-width: 300px;

  .poll-thumb {
    width: 32px;
    height: 32px;
    position: relative;

    .bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: brightness(0.75);
      mix-blend-mode: luminosity;
      border-radius: 4px;
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      height: 1.5rem;
      transform: translate(-50%, -50%);
    }

    + .custom-label {
      width: calc(100% - 70px) !important;
    }
  }

  .poll-label-img {
    width: 50px;
    height: 50px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  &.gallery {
    max-width: 250px;

    .media-thumb {
      width: 64px;
      height: 64px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }

    .col-4 {
      padding: 8px;
    }

    &.gallery-blank {
      width: 270px;
      height: 100px;
    }
  }

  &.files {
    width: 300px;
  }

  .file-listing {
    &:last-child {
      border-bottom: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  .form-check-label {
    .custom-label {
      width: calc(100% - 30px);
    }

    .voter {
      width: 1rem;
      height: 1rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
      }
    }
  }
}

.modal-options {
  .modal-content {
    border-radius: 0;
    background: transparent;

    .modal-body {
      li {
        padding: 14px 20px;
        background: #f8f8f8;
        border-bottom: 1px solid $border-color;

        a {
          gap: 0.5rem;
          display: flex;
        }

        &:first-child {
          border-top-left-radius: 14px;
          border-top-right-radius: 14px;
        }

        &:last-child {
          border-bottom-left-radius: 14px;
          border-bottom-right-radius: 14px;
        }
      }

      .cancel {
        background: #f8f8f8;
        border-radius: 14px;
      }
    }
  }
}

.swiper-slide {
  cursor: grab;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide {
  width: auto !important;
}

.theme-dropdwon {
  .dropdown-menu {
    padding: 0;
    overflow: hidden;

    .dropdown-item {
      font-size: 14px;
      color: $gray-600;
      padding: 0.5rem 1rem;

      &:hover,
      &:focus,
      &:active {
        color: $gray-600 !important;
        background: $gray-400 !important;
      }
    }
  }

  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
}

.user-thumb-overlap {
  display: flex;
  margin-left: -4px;

  .avatar {
    border: 4px solid white;
    margin-right: -15px;
    overflow: hidden;

    &:last-child {
      margin-right: 0;
    }
  }

  .img-frame,
  span {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 100%;
    border: 4px solid white;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:not(:first-child) {
      margin-left: -12px;
    }

  }

  &.image-sm,
  &.initials-sm {
    margin-left: 0;

    .img-frame,
    span {
      width: 1rem;
      height: 1rem;
      font-size: 9px;
      border: 0;

      &:not(:first-child) {
        margin-left: -4px;
      }
    }
  }
}

.text-blue {
  color: #007281;
}

.text-blue-50 {
  color: #6ea6c6;
}

.icon-transparent {
  width: 40px;
  height: 40px;
  display: flex;
  cursor: pointer;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background: rgba($color: white, $alpha: 0.5);
}

.create-event {
  right: 0;
  bottom: 0;
  margin: 1rem;
  position: fixed;
}

.recurring-option {
  .listing {
    gap: 1rem;
    padding: 0;
    display: flex;
    list-style: none;
    margin-block: 1.5rem;

    li {
      border-radius: 1rem;
      padding: 0.35rem 0.84rem;
      border: 1px solid $gray-900;

      &.selected {
        color: $white;
        background: $primary;
        border: 1px solid $primary;
      }
    }

    &.days {
      li {
        padding: 0;
        width: 33px;
        height: 33px;
        display: flex;
        align-items: center;
        border-radius: 100%;
        justify-content: center;
        font-size: 12px;
      }
    }
  }

  .rec-count {
    width: 32px;
    height: 32px;
    color: $white;
    line-height: 32px;
    border-radius: 10px;
    margin-inline: 1rem;
    display: inline-flex;
    background: $primary;
    justify-content: center;
  }
}

.success-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90vh;
}

::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
  background-color: $gray-400;
}

::-webkit-scrollbar-thumb {
  background-color: #dadada;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 768px) {
  .auth-wrapper {
    > div {
      max-width: 475px;
    }
  }
  header {
    background: #fff;
    box-shadow: 0 0 20px #0000000d;
  }
  .box {
    .page-title {
      .title-form {
        width: 300px;
      }
    }
  }
}

@media (max-width: 991px) {
  .msg-listing {
    padding: 0;
    max-height: 100%;
    overflow: initial;
  }
  .message-box {
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    position: fixed;
    padding: 1rem !important;

    .chatroom {
      max-height: calc(100vh - 160px);
    }
  }
}

@media (max-width: 767px) {
  body {
    background: $white;

    main {
      &.py-4 {
        padding: 0 0 6rem !important;
      }
    }
  }
  .auth-wrapper {
    padding-block: 1rem 1.5rem;

    .auth-box {
      margin: 0;
      padding: 0.75rem;
      box-shadow: none;
    }

    .m-f-36 {
      font-size: 36px;
    }
  }
  .theme-nav-outer {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 78px;
    z-index: 9999;
    position: fixed;
    background: $white;
    padding: 0.25rem 1.5rem 0;
    box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.06);

    .theme-nav {
      margin: auto;
      display: flex;
      min-width: 510px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;

      .icon-event {
        margin-top: -3rem;
      }
    }
  }
  .offcanvas-body {
    padding: 0 1rem 5rem;
  }
  .box {
    //padding: 0rem 0;

    .page-title {
      flex-wrap: wrap;

      &.main {
        .title-form {
          width: 100%;
          margin-top: 1rem !important;
        }

        position: relative;

        .options {
          position: absolute;
          top: calc(100% + 22px);
          right: 0;
        }
      }
    }

    .float-card {
      position: fixed;
      top: 25px;
      z-index: 2;
      width: 92.5%;
    }

    hr {
      margin-inline: -0.75rem;
    }
  }
  .theme-tab {
    &.nav-tabs {
      justify-content: center;
    }
  }
  .expense-detail-listing {
    .price {
      font-size: 1rem;
    }

    .day {
      font-size: 13px;

      .user-initials {
        width: 1rem;
        height: 1rem;
        font-size: 11px;
      }
    }
  }
  .balance {
    justify-content: center;

    p {
      flex: 1 100%;
      text-align: center;

      strong,
      .price {
        min-width: 100px;
        display: inline-flex;
      }

      .price {
        min-width: 130px;
        justify-content: center;
      }
    }
  }
  .theme-modal {
    z-index: 9999;
    bottom: 80px;
  }
  .message-box {
    .chatroom {
      max-height: calc(100vh - 160px);
    }
  }
  .box-top {
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;

    .box {
      border-radius: 0;
      margin-bottom: 85px;

      .event-detail {
        padding-inline: 1rem;

        .event-cover-img {
          .controls {
            padding-inline: 1rem;
          }
        }

        .event-card {
          margin-inline: 0;

          h4 {
            line-height: 1rem;
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .offcanvas {
    width: 100% !important;
  }
  .theme-nav-outer {
    .theme-nav {
      min-width: 100%;
    }
  }
  .theme-modal {
    bottom: 0px;
    padding-right: 0 !important;

    .modal-dialog {
      margin: 0;

      .modal-content {
        border: none;
        border-radius: 10px 10px 0 0;
      }
    }
  }
}
