@font-face {
  font-family: "product_sansregular";
  src: url("../../../public/fonts/product_sans_regular-webfont.woff2") format("woff2"),
    url("../../../public/fonts/product_sans_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "product_sansbold";
  src: url("../../../public/fonts/product_sans_bold-webfont.woff2") format("woff2"),
    url("../../../public/fonts/product_sans_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
